.app-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px;
    background-color: #fff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    z-index: 1000;
}

.header-left,
.header-right {
    flex: 1;
    display: flex;
    justify-content: center;
}

.header-title {
    flex: 2;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #333;
}

.map-button,
.filter-button {
    background-color: transparent;
    border: none;
    font-size: 16px;
    color: #007bff;
    cursor: pointer;
}

.map-button:hover,
.filter-button:hover {
    color: #0056b3;
}
