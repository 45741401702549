.app-footer {
    display: flex;
    justify-content: space-around;
    align-items: flex-end; /* Выравниваем кнопки по нижнему краю футера */
    padding: 4px 0; /* Убираем нижний отступ */
    background-color: #fff;
    box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.1);
    position: fixed;
    bottom: 0;
    width: 100%;
}

.footer-button {
    margin: 0; /* Убираем все отступы */
    padding: 0; /* Убираем все внутренние отступы */
    background-color: transparent;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    color: #888;
    cursor: pointer;
}

.footer-button .icon-vacancies:before {
    content: url(images/vacancy.svg); /* Временная иконка */
    font-size: 24px;
}

.footer-button .icon-favorites:before {
    content: url(images/heart.svg); /* Временная иконка */
    font-size: 32px;
}

.footer-button .icon-messages:before {
    content: url(images/message.svg); /* Временная иконка */
    font-size: 24px;
}

.footer-button .icon-profile:before {
    content: url(images/profile.svg); /* Временная иконка */
    font-size: 24px;
}

.footer-button span {
    margin-top: 4px;
}

.footer-button.active {
    color: #007bff;
}

.footer-button:hover {
    color: #0056b3;
}
