.vacancy-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
}

.vacancy-card {
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    padding: 16px;
    position: relative;
    width: calc(100% - 32px); /* Уменьшаем ширину, чтобы оставить отступ */
    margin-right: 16px; /* Отступ справа для карточки */
}

.vacancy-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}

.vacancy-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 16px;
}

.vacancy-info {
    flex: 1;
}

.vacancy-title {
    font-size: 18px;
    margin: 0;
}

.vacancy-company {
    color: #666;
    font-size: 14px;
    margin: 4px 0;
}

.vacancy-location {
    color: #999;
    font-size: 12px;
}

.vacancy-category {
    font-size: 12px;
    color: red;
    padding: 4px 8px;
    background-color: #ffecec;
    border-radius: 4px;
}

.urgent {
    color: #ff3b30;
    background-color: #ffecec;
}

.vacancy-details {
    font-size: 14px;
    color: #333;
    margin-bottom: 16px;
}

.vacancy-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.apply-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 8px 16px;
    border-radius: 8px;
    cursor: pointer;
}

.apply-button:hover {
    background-color: #0056b3;
}

.action-icons {
    display: flex;
    gap: 12px;
}

.icon-heart::before {
    content: url(images/heart.svg); /* Временная иконка, можно использовать библиотеку иконок */
    font-size: 20px;
}

.icon-share::before {
    content: url(images/share.svg); /* Временная иконка */
    font-size: 20px;
}
